import DockerConfig from "@/model/DockerConfig";

export default class DockerRun extends DockerConfig {

    constructor(dockerConfig: DockerConfig) {
        super(
            dockerConfig.containerName,
            dockerConfig.image,
            dockerConfig.version,
            dockerConfig.envs,
            dockerConfig.ports,
            dockerConfig.volumes,
            dockerConfig.links,
            dockerConfig.restartPolicy,
            null);
    }

    public get text(): string {
        return 'Docker run command';
    }

    generate(): string {
        let string = `docker run`; // Basic command

        if (this.containerName) {
            string += ` --name ${this.containerName}`; // Container's name
        }

        if (Object.keys(this.envs).length > 0) { // If envs are set
            for (const [key, value] of Object.entries(this.envs)) { // Iterates over envs
                if (value !== null) { // If value is not null
                    string += ` -e`; // Env flag
                    string += ` ${key}=${value}`; // Add env
                }
            }
        }

        if (Object.keys(this.ports).length > 0) { // If ports are set
            for (const [key, value] of Object.entries(this.ports)) { // Iterates over ports
                if (value !== null) { // If value is not null
                    string += ` -p`; // Ports flag
                    string += ` ${value}:${key}`; // Add port
                }
            }
        }

        if (Object.keys(this.volumes).length > 0) { // If volumes are set
            for (const [key, value] of Object.entries(this.volumes)) { // Iterates over volumes
                if (value !== null) { // If value is not null
                    string += ` -v`; // Volumes flag
                    string += ` ${value}:${key}`; // Add volume
                }
            }
        }

        if (Object.keys(this.links).length > 0) { // If links are set
            for (const [key, value] of Object.entries(this.links)) { // Iterates over links
                if (value !== null) { // If value is not null
                    string += ` --link`; // Links flag
                    string += ` ${value}:${key}`; // Add link
                }
            }
        }

        if (this.restartPolicy) {
            string += ` --restart ${this.restartPolicy}`; // Restart policy
        }

        string += ` -d`; // Daemon flag
        string += ` ${this.image.name}:${this.version}`;

        return string;
    }

}
