import DockerConfig from "@/model/DockerConfig";
import { v4 as uuidv4 } from 'uuid';

export default class DockerCompose extends DockerConfig {

    constructor(dockerConfig: DockerConfig) {
        super(
            dockerConfig.containerName,
            dockerConfig.image,
            dockerConfig.version,
            dockerConfig.envs,
            dockerConfig.ports,
            dockerConfig.volumes,
            dockerConfig.links,
            dockerConfig.restartPolicy,
            dockerConfig.serviceName);
    }

    public get text(): string {
        return 'docker-compose.yml file';
    }

    generate(headerAndService = true): string {
        let string = ``;
        let end = ``;
        const explicitVolumes = [];

        if (headerAndService) {
            string += `version: '3.8'<br/>`; // Version
            string += `services:<br/>`; // Opens list of services
        }

        string += `&nbsp;${this.serviceName ?? this.containerName ?? uuidv4().replace(/-/g, '_')}:<br/>`; // Container's name
        string += `&nbsp;&nbsp;image: '${this.image.name}:${this.version}'<br/>`;

        if (this.containerName) {
            string += `&nbsp;&nbsp;container_name: '${this.containerName}'<br/>`;
        }

        if (this.restartPolicy) {
            string += `&nbsp;&nbsp;restart: '${this.restartPolicy}'<br/>`;
        }

        if (Object.keys(this.envs).length > 0) { // If envs are set
            string += `&nbsp;&nbsp;environment:<br/>`; // Envs flag
            for (const [key, value] of Object.entries(this.envs)) { // Iterates over envs
                if (value !== null) { // If value is not null
                    string += `&nbsp;&nbsp;&nbsp;${key}: ${value}<br/>`; // Add env
                }
            }
        }

        if (Object.keys(this.ports).length > 0) { // If ports are set
            string += `&nbsp;&nbsp;ports:<br/>`; // Ports flag
            for (const [key, value] of Object.entries(this.ports)) { // Iterates over ports
                if (value !== null) { // If value is not null
                    string += `&nbsp;&nbsp;&nbsp;- ${value}:${key}<br/>`; // Add port
                }
            }
        }

        if (Object.keys(this.volumes).length > 0) { // If volumes are set
            string += `&nbsp;&nbsp;volumes:<br/>`; // Volumes flag
            for (const [key, value] of Object.entries(this.volumes)) { // Iterates over volumes
                if (value !== null) { // If value is not null
                    string += `&nbsp;&nbsp;&nbsp;- ${value}:${key}<br/>`; // Add volume

                    if (!value.includes('/') && !value.includes('\\')) {
                        explicitVolumes.push(value);
                    }
                }
            }
        }

        if (explicitVolumes.length > 0) {
            end += `<br/>volumes:<br/>`;
            for (const value of explicitVolumes) {
                end += `&nbsp;${value}:`;
            }
        }

        if (Object.keys(this.links).length > 0) { // If links are set
            string += `&nbsp;&nbsp;links:<br/>`; // Links flag
            for (const [key, value] of Object.entries(this.links)) { // Iterates over links
                if (value !== null) { // If value is not null
                    string += `&nbsp;&nbsp;&nbsp;- "${key}:${value}"<br/>`; // Add link
                }
            }
        }

        return string + end;
    }

}
