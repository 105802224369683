












import Vue from 'vue';

import IActionItem from '@/interface/IActionItem';

export default Vue.extend({
  name: 'ActionButtons',
  props: {
   actionButtons: {
      type: Array as () => IActionItem[],
      default: []
    },
    showName: {
      type: Boolean,
      default: true
    }
  }
});
