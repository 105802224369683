



































































































































































































































































































































































import Vue from "vue";
import axios from "axios";

import LabelInput from "@/components/LabelInput.vue";
import ImageItem from "@/components/ImageItem.vue";
import ActionButtons from "@/components/ActionButtons.vue";

import DockerConfig from "@/model/DockerConfig";
import IImage from "@/interface/IImage";
import IEnv from "@/interface/IEnv";
import IPort from "@/interface/IPort";
import IVolume from "@/interface/IVolume";
import ILink from "@/interface/ILink";
import IActionItem from "@/interface/IActionItem";

import { RESTART_POLICIES } from "@/common/restart-policies";

interface IKeyword {
  keyword: string;
  icon: string;
}

export default Vue.extend({
  name: "ContainerCreator",
  components: {
    LabelInput,
    ImageItem,
    ActionButtons,
  },
  props: {
    id: {
      type: String,
    },
    images: {
      type: Array as () => IImage[],
      default: [],
    },
    showGenerateButton: {
      type: Boolean,
      default: false,
    },
    containersCount: {
      type: Number,
      default: 1,
    },
    actionButtons: {
      type: Array as () => IActionItem[],
      default: [],
    },
  },
  data: () => {
    return {
      COMMONLY_USED_KEYWORDS: [
        {
          keyword: "database",
          icon: "database",
        },
        {
          keyword: "http",
          icon: "globe-europe",
        },
        {
          keyword: "cloud",
          icon: "cloud",
        },
      ] as IKeyword[],

      RESTART_POLICIES: RESTART_POLICIES,

      suggestedImages: null as IImage[] | null | undefined,
      availableEnvs: null as IEnv[] | null,
      availablePorts: null as IPort[] | null,
      availableVolumes: null as IVolume[] | null,
      availableLinks: null as ILink[] | null,

      // Selections
      containerName: null as string | null,
      image: null as IImage | null,
      version: "" as string | null,
      envs: {} as { [key: string]: string },
      ports: {} as { [key: string]: string },
      volumes: {} as { [key: string]: string },
      links: {} as { [key: string]: string },

      showAdvanced: false as boolean,
      restartPolicy: RESTART_POLICIES[0],
      serviceName: null as string | null,
    };
  },
  computed: {
    boxShadow: function() {
      return (
        "0 0 26px -8px " +
        (this.image !== null && this.image.color
          ? this.image.color
          : "lightgray")
      );
    },
    isOnline: function() {
      return window.navigator.onLine;
    },
  },
  methods: {
    suggestImages: async function(event: string) {
      const value = event.trim().toLowerCase();
      if (value === "" || value.length < 3) {
        this.suggestedImages = null;
      } else {
        const MAX_RESULTS = 5;
        let apiUnreachable = !window.navigator.onLine;

        if (window.navigator.onLine) {
          this.suggestedImages = undefined;
          try {
            const resp = await axios // Load all known services for auto-completion and other magics
              .post(`${process.env.VUE_APP_API_URL}/api/images/suggest`, {
                input: value,
              });

            this.suggestedImages = resp.data;
          } catch (e) {
            apiUnreachable = true;
          }
        }

        if (apiUnreachable) {
          // if API is unreachable (client is offline, API is down or blocked by firewall)
          this.suggestedImages = []; // Suggested images array gets initialized to an empty array
          let i = 0;
          for (const image of this.images) {
            // For each image in images.json
            if (
              image.name.toLowerCase().indexOf(value) > -1 || // If the image's name is contains user input, the image is valid and gets shown
              image.keywords.includes(value)
            ) {
              this.suggestedImages.push(image);
              i++;
            }

            if (i >= MAX_RESULTS) {
              // Iterates for a max of MAX_RESULTS valid images
              break;
            }
          }

          if (this.suggestedImages.length > 0) {
            this.suggestedImages.sort((a, b) => {
              // Arrow function to sort images by commonName
              const commonNameA = a.commonName.toUpperCase();
              const commonNameB = b.commonName.toUpperCase();

              let comparison = 0;
              if (commonNameA > commonNameB) {
                comparison = 1;
              } else if (commonNameA < commonNameB) {
                comparison = -1;
              }
              return comparison;
            });
          }
        }
      }
    },
    selectImage: function(imageName: string) {
      if (this.suggestedImages) {
        this.image =
          this.suggestedImages.find((image) => image.name === imageName) ||
          null;
        this.suggestedImages = null; // Removes list of suggested images
        this.selectVersion("latest"); // Automatically set version to latest
      }
    },
    selectVersion: function(version: string) {
      this.$emit("update:showGenerateButton", true);
      this.version = version; // If from HTML event, use that otherwise threat version as a string type (like "8.0")

      if (this.image?.envs) {
        this.availableEnvs = this.image.envs.filter((env) =>
          env.versions.includes(this.version || "")
        );
      } else {
        this.availableEnvs = [];
      }

      if (this.image?.ports) {
        this.availablePorts = this.image.ports.filter((port) =>
          port.versions.includes(this.version || "")
        );
      } else {
        this.availablePorts = [];
      }

      if (this.image?.volumes) {
        this.availableVolumes = this.image.volumes.filter((volume) =>
          volume.versions.includes(this.version || "")
        );
      } else {
        this.availableVolumes = [];
      }

      if (this.image?.links) {
        this.availableLinks = this.image.links.filter((link) =>
          link.versions.includes(this.version || "")
        );
      } else {
        this.availableLinks = [];
      }
      this.input(null, null, null);
    },
    changeImage: function() {
      this.image = null;
      this.version = null;
      this.envs = {};
      this.ports = {};
      this.volumes = {};
      this.links = {};
      this.input("restartPolicy", null, RESTART_POLICIES[0].value);
      this.input(null, null, null);
    },
    input: function(
      type: string | null,
      id: string | null,
      value: string | null
    ) {
      const normalizeInput = (input: string) => {
        return input.trim().replace(/\s/g, "_");
      };

      value = value || null;
      if (type === "name") {
        this.containerName =
          value !== null ? DockerConfig.normalizeContainerName(value) : null;
      } else if (type === "restartPolicy") {
        this.restartPolicy =
          RESTART_POLICIES.find((p) => p.value === value) ||
          RESTART_POLICIES[0];
      } else if (type === "serviceName") {
        this.serviceName =
          value !== null ? DockerConfig.normalizeContainerName(value) : null;
      } else if (type !== null && id !== null) {
        switch (type) {
          case "env":
            if (value === null) {
              delete this.envs[id];
            } else {
              this.envs[id] = value;
            }
            break;
          case "port":
            if (value === null) {
              delete this.ports[id];
            } else {
              this.ports[id] = normalizeInput(value);
            }
            break;
          case "volume":
            if (value === null) {
              delete this.volumes[id];
            } else {
              this.volumes[id] = normalizeInput(value);
            }
            break;
          case "link":
            if (value === null) {
              delete this.links[id];
            } else {
              this.links[id] = normalizeInput(value);
            }
            break;
        }
      }

      this.$emit(
        "content-updated",
        this.image && this.version
          ? new DockerConfig(
              this.containerName,
              this.image,
              this.version,
              this.envs,
              this.ports,
              this.volumes,
              this.links,
              this.restartPolicy.value,
              this.serviceName
            )
          : null
      ); // Sends DockerConfig if image is selected, otherwise sends null
    },
  },
});
