






















import Vue from "vue";

export default Vue.extend({
  name: "ImageItem",
  props: {
    image: String,
    name: String,
    commonName: String,
    description: String,
    link: String,
    showImage: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    imagePath: function() {
      return this.image ?? `img/images/${this.name.replace("/", "-")}.png`;
    },
  },
});
