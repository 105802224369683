


































import { v4 as uuidv4 } from "uuid";
import Vue from "vue";

export enum ETagType {
  TEXT,
  SELECT,
}

export default Vue.extend({
  name: "LabelInput",
  props: {
    icon: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: () => uuidv4(),
    },
    tagType: {
      type: Number,
      default: ETagType.TEXT,
    },
    label: {
      type: String,
      default: null,
    },
    value: String,
    placeholder: String,
    type: {
      type: String,
      default: "text",
    },
    classes: {
      type: String,
      default:
        "shadow appearance-none border rounded w-full pt-2 pb-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline transition ease-in-out duration-200",
    },

    min: {
      type: Number,
    },
    max: {
      type: Number,
    },

    options: {
      type: Array,
    },
  },
});
