

















































import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

// @ is an alias to /src
import ContainerCreator from "@/components/ContainerCreator.vue";
import IContainer from "@/interface/IContainer";
import DockerCompose from "@/model/DockerCompose";
import DockerRun from "@/model/DockerRun";
import DockerConfig from "@/model/DockerConfig";
import IImage from "@/interface/IImage";
import IActionItem from "@/interface/IActionItem";

interface IContainerName {
  left: string[];
  right: string[];
}

interface IGeneration {
  id: string;
  text: string;
  result: string;
}

export default Vue.extend({
  name: "Home",
  components: {
    ContainerCreator,
  },
  data: () => {
    return {
      images: [] as IImage[] | null,
      containersNames: {} as IContainerName | null,

      containers: [
        {
          id: uuidv4(),
          generated: null as string | null,
        },
      ] as IContainer[],
      generations: [] as IGeneration[],

      askBeforeUnload: true,
    };
  },
  methods: {
    getActionButtons: function(container: IContainer): IActionItem[] {
      if (this.containers.length > 1) {
        const isFirstContainer =
          this.containers.findIndex((c) => c === container) === 0;
        const isLastContainer =
          this.containers.findIndex((c) => c === container) ===
          this.containers.length - 1;
        const canDelete = this.containers.length > 1;

        const classes = [
          "disabled:opacity-50",
          "bg-transparent",
          "hover:bg-blue-500",
          "text-blue-700",
          "font-semibold",
          "hover:text-white",
          "py-1",
          "px-2",
          "border",
          "border-blue-500",
          "hover:border-transparent",
          "rounded",
          "mr-1",
          "mb-1",
          "transition",
          "ease-in-out",
          "duration-200",
          "text-sm",
        ];

        return [
          {
            id: "move-up",
            name: "Move up",
            icon: "chevron-up",
            enabled: !isFirstContainer,
            classes,
          },
          {
            id: "move-down",
            name: "Move down",
            icon: "chevron-down",
            enabled: !isLastContainer,
            classes,
          },
          {
            id: "add-above",
            name: "Add container above",
            icon: "plus",
            enabled: true,
            classes,
          },
          {
            id: "delete",
            name: "Delete",
            icon: "times",
            enabled: canDelete,
            classes,
          },
        ];
      }

      return [];
    },
    addContainer: function(position: number | null = null) {
      const container = {
        id: uuidv4(),
        generated: null,
      };

      if (position !== null) {
        this.containers.splice(position, 0, container);
      } else {
        this.containers.push(container);
      }
    },
    removeContainer: function(id: string) {
      this.containers.splice(
        this.containers.findIndex(
          (container: IContainer) => container.id === id
        ), // Finds index of given container id
        1
      );

      this.generate();
    },
    containerUpdated: function(container: string, generated: DockerConfig) {
      const containerF = this.containers.find(
        (curr: IContainer) => curr.id === container
      );
      if (containerF) {
        containerF.generated = generated;
        this.generate();
      }
    },
    action: function(event: { [key: string]: string }) {
      const position = this.containers.findIndex(
        (c) => c.id === event.containerId
      );

      switch (event.action) {
        case "move-up":
          this.containers.splice(
            position - 1,
            0,
            this.containers.splice(position, 1)[0]
          );
          this.generate();
          break;
        case "move-down":
          this.containers.splice(
            position + 1,
            0,
            this.containers.splice(position, 1)[0]
          );
          this.generate();
          break;
        case "add-above":
          this.addContainer(position);
          break;
        case "delete":
          this.removeContainer(event.containerId);
          break;
      }
    },
    generate: function() {
      this.generations = [];

      const dockerRun: DockerRun[] = [];
      const dockerCompose: DockerCompose[] = [];

      if (this.containers.length > 0) {
        this.containers.forEach((container: IContainer) => {
          if (container.generated !== null) {
            dockerRun.push(new DockerRun(container.generated));
            dockerCompose.push(new DockerCompose(container.generated));
          }
        });

        if (dockerRun.length > 0) {
          this.generations.push({
            id: "run",
            text: dockerRun[0].text,
            result: dockerRun.map((run) => run.generate()).join(" && "),
          });
        }

        if (dockerCompose.length > 0) {
          this.generations.push({
            id: "compose",
            text: dockerCompose[0].text,
            result: dockerCompose
              .map((compose, i) => compose.generate(i === 0))
              .join(""),
          });
        }
      }
    },
    dockerunhost: function(type: string) {
      if (this.generations.length > 0) {
        const f = this.generations.find((g) => g.id === type);

        if (f) {
          const transform = (result: string) => {
            return result.replace(/&nbsp;/g, " ").replace(/<br\/>/g, "\n");
          };

          this.askBeforeUnload = false;
          window.location.href =
            "dockerun:" +
            btoa((type !== "run" ? type : "") + transform(f.result));
          this.askBeforeUnload = true;
        }
      }
      return "dockerun:";
    },
    beforeUnloadWarning: function() {
      const userAgent = navigator.userAgent.toLowerCase();
      if (
        this.generations.length > 0 &&
        this.askBeforeUnload &&
        userAgent.indexOf(" electron/") === -1
      ) {
        return "DEFAULT_BROWSER_MESSAGE";
      }
    },
  },
  mounted() {
    axios // Load all known services for auto-completion and other magics
      .get("images.json")
      .then((resp) => (this.images = resp.data.images))
      .catch(() => (this.images = null));
  },
  created() {
    window.onbeforeunload = this.beforeUnloadWarning;
  },
  beforeRouteLeave(to, from, next) {
    if (this.generations.length > 0) {
      const answer = window.confirm(
        "Do you really want to leave? You will lose containers configurations!"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  beforeDestroy() {
    window.onbeforeunload = function() {
      //
    };
  },
});
