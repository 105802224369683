import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCube, faAtom, faDatabase, faGlobeEurope, faCloud, faSlidersH, faDoorOpen, faHdd,
  faLink, faPencilAlt, faCopy, faPlus, faTimes, faTasks, faRedoAlt, faRocket, faDownload, faChevronUp,
  faChevronDown, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faWindows } from '@fortawesome/free-brands-svg-icons';

library.add(faCube, faAtom, faDatabase, faGlobeEurope, faCloud, faSlidersH, faDoorOpen, faHdd,
  faLink, faPencilAlt, faCopy, faPlus, faTimes, faTasks, faRedoAlt, faRocket, faDownload, faWindows,
  faChevronUp, faChevronDown, faCircleNotch);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
  created() {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(' electron/') > -1) {
      this.$router.push('/'); // Needed for Electron
    }
  }
}).$mount('#app');
