import IImage from '@/interface/IImage';

export default class DockerConfig {

    private readonly _containerName: string | null = null;
    private readonly _image: IImage;
    private readonly _version: string;
    private readonly _envs: { [key: string]: string };
    private readonly _ports: { [key: string]: string };
    private readonly _volumes: { [key: string]: string };
    private readonly _links: { [key: string]: string };

    private readonly _restartPolicy: string | null = null;
    private readonly _serviceName: string | null = null;

    public constructor(containerName: string | null, image: IImage, version: string, envs: { [key: string]: string }, ports: { [key: string]: string },
        volumes: { [key: string]: string }, links: { [key: string]: string }, restartPolicy: string | null, serviceName: string | null) {
        if (containerName) {
            this._containerName = DockerConfig.normalizeContainerName(containerName);
        }
        this._image = image;
        this._version = version;
        this._envs = envs;
        this._ports = ports;
        this._volumes = volumes;
        this._links = links;

        if (restartPolicy !== 'no') {
            this._restartPolicy = restartPolicy;
        }

        if (serviceName) {
            this._serviceName = DockerConfig.normalizeContainerName(serviceName);
        }
    }

    // Replaces whitespaces with underscores
    public static normalizeContainerName(containerName: string): string {
        return containerName.replace(/ /g, "_");
    }

    get containerName(): string | null {
        return this._containerName;
    }

    get image(): IImage {
        return this._image;
    }

    get version(): string {
        return this._version;
    }

    get envs(): { [key: string]: string } {
        return this._envs;
    }

    get ports(): { [key: string]: string } {
        return this._ports;
    }

    get volumes(): { [key: string]: string } {
        return this._volumes;
    }

    get links(): { [key: string]: string } {
        return this._links;
    }

    get restartPolicy(): string | null {
        return this._restartPolicy;
    }

    get serviceName(): string | null {
        return this._serviceName;
    }
}
