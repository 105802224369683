import RestartPolicy from '@/model/RestartPolicy';

export const RESTART_POLICIES: RestartPolicy[] = [
    {
        value: 'no',
        text: 'no',
        description: 'Do not automatically restart the container',
    },
    {
        value: 'on-failure',
        text: 'on-failure',
        description: 'Restart the container if it exits due to an error, which manifests as a non-zero exit code',
    },
    {
        value: 'always',
        text: 'always',
        description: 'Always restart the container if it stops. If it is manually stopped, it is restarted only when Docker daemon restarts',
    },
    {
        value: 'unless-stopped',
        text: 'unless-stopped',
        description: 'Similar to always, except that when the container is stopped, it is not restarted even after Docker daemon restarts',
    }
];
